
















import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TestTeacherHistoryHeader from '@/components/organisms/TestTeacherHistoryHeader.vue'
import StudentAchievement from '@/components/organisms/StudentAchievement.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import StudentObjective from '@/components/organisms/StudentObjective.vue'
import ModalObjectiveDetail from '@/components/organisms/ModalObjectiveDetail.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import StudentGoalApi from '@/mixins/studentGoals/StudentGoalApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    StudentHistorySidebar,
    TestTeacherHistoryHeader,
    StudentAchievement,
    ColoredBox,
    TitleBase,
    TitleTextBase,
    StudentObjective,
    ModalObjectiveDetail,
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class TargetSituation extends Mixins(StudentGoalApi, ClassModeMethods) {
  private get classMode() {
    return this.CLASS_MODE.TEST
  }

  private studentUserId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  private currentGoal: StudentGoal | null = null

  private prevGoals: StudentGoal[] = []

  @Ref() modalObjectiveDetail!: ModalObjectiveDetail

  private showObjectiveDetail(goal: StudentGoal) {
    this.modalObjectiveDetail.showCurrentGoal(goal)
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    this.currentGoal = await this.loadCurrentGoal(this.classMode, this.studentUserId)
    this.prevGoals = await this.loadPrevGoals(this.classMode, this.studentUserId)
    Vue.prototype.$loading.complete()
  }
}
